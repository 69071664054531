// @ts-ignore
const { wafer } = window;

/**
 * This is util method to remove a node from its parent
 * @param {Node} node to be removed
 */
function removeNode(node) {
    node && node.parentNode && node.parentNode.removeChild(node);
}

/**
 * onclick handler to close button on featurebar
 */
function _handleCloseButton() {
    // feature bar close button element
    const closeBtns = document.getElementsByClassName('featureBtn_close') || [];
    const featureBarCloseBtn = closeBtns[0];

    if (featureBarCloseBtn) {
        featureBarCloseBtn.addEventListener(
            'click',
            function close() {
                const featureBar = this.parentNode;
                removeNode(featureBar);
            },
            false
        );
    }
}

// add click handler for featurebar on DOMContentLoaded
window.addEventListener('DOMContentLoaded', _handleCloseButton, false);
